<template>
    <div>
        <el-table :data="page.list" border v-loading='listLoading' height="300">
            <el-table-column prop="collectionName" label="系列简称" width="140" />
            <el-table-column prop="tokenId" label="tokenId" width="100"  />
            <el-table-column prop="amount" label="作品发送数量" width="120" />
            <el-table-column prop="txFee" label="链费" width="100" />
            <el-table-column prop="txid" label="TxHash" width="200" />
            <el-table-column prop="toAddress" label="To Address" width="200" />
            <el-table-column prop="batchStatus" label="状态" >
                <template #default="scope">
                    {{batchStatus_[scope.row.withdrawStatus]}}
                </template>
            </el-table-column>
            <el-table-column prop="applyTime" label="创建时间" width='160'>
                <template #default="scope">
                    {{turnTimeFun(scope.row.applyTime)}}
                </template>
            </el-table-column>
            <el-table-column prop="batchSendTime" label="发送时间" width='165'>
                <template #default="scope">
                    {{turnTimeFun1(scope.row.batchSendTime)}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width='140'>
                <el-button @click="getData" type='text'>元数据</el-button>
            </el-table-column>

        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineExpose } from 'vue'
    // import { collection } from "@/const/crud/cryptocurrency/customerWithdrawalArr";
    import mixins from '@/views/mixins/page'
    import pageCom from '@/components/pageCom.vue'
    import { turnTimeFun,turnTimeFun1 } from '@/utils/util.js'  

    let pageObj = mixins(['pageByMerNFTBatch'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    const checkItem = ref(false)
    let batchStatus_ = ['待发送','发送中','发送成功','发送失败','部分成功']
    

    const getData = (e)=>{
        console.log(e)
    }
    const init_ = (e)=>{
        checkItem.value = false
        updateOtherParm(e)
        getList(1)
    }
    defineExpose({init_})
    
</script>

<style lang="scss" scoped>
</style>